/* eslint-disable react/jsx-props-no-spreading */
import SuspenseLoader from 'components/SuspenseLoader';
import { Suspense, lazy } from 'react';

const Loader = (Component: any) => {
  return (props: any) => {
    return (
      <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
      </Suspense>
    );
  };
};

interface IRoutes {
  path: string;
  title: string;
  component: JSX.Element;
  isPrivate: boolean;
}

const Home = Loader(
  lazy(() => {
    return import('pages/Home');
  }),
);

const RecoveryPassword = Loader(
  lazy(() => {
    return import('pages/Recovery');
  }),
);

const Signin = Loader(
  lazy(() => {
    return import('pages/Signin');
  }),
);

const Layout = Loader(
  lazy(() => {
    return import('components/Layout');
  }),
);

const Assignees = Loader(
  lazy(() => {
    return import('pages/Assignees/FormView');
  }),
);

const Dashboard = Loader(
  lazy(() => {
    return import('pages/Dashboard');
  }),
);

const ReportCommission = Loader(
  lazy(() => {
    return import('pages/Reports/Commission');
  }),
);

const Reporttransactions = Loader(
  lazy(() => {
    return import('pages/Reports/Transactions');
  }),
);

const AssigneesList = Loader(
  lazy(() => {
    return import('pages/Assignees/ListView');
  }),
);

const EntryList = Loader(
  lazy(() => {
    return import('pages/Entry/ListView');
  }),
);

const ListUsers = Loader(
  lazy(() => {
    return import('pages/UserManagementSettigns/Users/ListView');
  }),
);

const User = Loader(
  lazy(() => {
    return import('pages/UserManagementSettigns/Users/FormView');
  }),
);
const NewUser = Loader(
  lazy(() => {
    return import('pages/UserManagementSettigns/Users/NewUser');
  }),
);

const Enqueue = Loader(
  lazy(() => {
    return import('pages/Equalizer/Queue');
  }),
);

const routes: IRoutes[] = [
  {
    path: '/',
    title: 'Home',
    component: <Home />,
    isPrivate: true,
  },
  {
    path: '/recoverypassword/:base64',
    title: 'Recovery Password',
    component: <RecoveryPassword />,
    isPrivate: false,
  },
  {
    path: '/signin',
    title: 'Signin',
    component: <Signin />,
    isPrivate: false,
  },
  {
    path: '/*',
    title: 'Layout',
    component: <Layout />,
    isPrivate: false,
  },
  {
    path: '/assignees/list',
    title: 'List distributors And Shopkeeper',
    component: <AssigneesList />,
    isPrivate: true,
  },
  {
    path: '/assignees/edit/:codigo_cliente',
    title: 'List distributors And Shopkeeper',
    component: <Assignees />,
    isPrivate: true,
  },
  {
    path: '/dashboard',
    title: 'dashboard',
    component: <Dashboard />,
    isPrivate: true,
  },
  {
    path: '/report/commission',
    title: 'List distributors And Shopkeeper',
    component: <ReportCommission />,
    isPrivate: true,
  },
  {
    path: '/report/transaciton',
    title: 'List distributors And Shopkeeper',
    component: <Reporttransactions />,
    isPrivate: true,
  },
  {
    path: '/entry/list',
    title: 'List entry',
    component: <EntryList />,
    isPrivate: true,
  },
  {
    path: '/userSettings/users',
    title: 'Users',
    component: <ListUsers />,
    isPrivate: true,
  },
  {
    path: '/userSettings/users/:id',
    title: 'Users',
    component: <User />,
    isPrivate: true,
  },
  {
    path: '/userSettings/users/NewUser',
    title: 'New User',
    component: <NewUser />,
    isPrivate: true,
  },
  {
    path: '/Equalizer/Queue',
    title: 'Users',
    component: <Enqueue />,
    isPrivate: true,
  },
];

export default routes;
