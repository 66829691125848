/* eslint-disable max-len */
/* eslint-disable no-param-reassign */

import { AxiosError } from 'axios';
import { defaultResponse } from 'interfaces/defaultResponse';
import { reduxTemplate } from 'interfaces/redux';
import { signupPayload } from 'interfaces/Signup';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import postSignup from './service';

export const newSignup = createAsyncThunk<defaultResponse, signupPayload, { rejectValue: reduxTemplate }>(
  'signup',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await postSignup(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

interface ISignupReducer extends reduxTemplate {
  signup: defaultResponse;

}

const initialState: ISignupReducer = {
  status: 'idle',
  message: '',
  signup: {
    status: '',
    message: '',
    rowsAffected: [],
    document: [],
  },
};

export const signupReducer = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    clearSignup: (state) => {
      state.status = 'idle';
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(newSignup.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(newSignup.fulfilled, (state, action: PayloadAction<defaultResponse>) => {
        state.status = 'completed';
        state.signup = action.payload;
      })
      .addCase(newSignup.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.message = (action.payload as reduxTemplate).message;
        }
      });
  },
});
export const { clearSignup } = signupReducer.actions;
export default signupReducer.reducer;
