import { format } from 'date-fns';
import { defaultResponse } from 'interfaces/defaultResponse';
import { signupPayload } from 'interfaces/Signup';
import api from 'services';

const postSignup = async (payload: signupPayload): Promise<defaultResponse> => {
  const dateFormated = format(payload.birthDate, 'yyyy-MM-dd');

  const response = await api.post<defaultResponse>('/signup', { ...payload, birthDate: dateFormated });
  return response.data;
};

export default postSignup;
